<template>
  <vue-scroll>

    <v-container fluid class="">

      <v-row class="mr-1">
        <v-col>

          <v-card>
            <v-card-title class="py-2">Gestion des communications</v-card-title>
            <v-card-subtitle>{{ editing ? "Modifier" : "Ajouter" }} une communication</v-card-subtitle>

            <v-card-text class="" ref="cardComAdd">
              <v-form ref="comAdd"
                      class="fill-height"
                      v-model="valid"
                      lazy-validation
              >
                <v-row>
                  <v-col cols="6">
                    <v-row no-gutters>
                      <v-col class="mr-2">
                        <v-text-field label="Titre (français)" v-model="creating.label"
                                      :rules="ValidationRules.required"></v-text-field>

                      </v-col>
                      <v-col class="ml-2">
                        <v-text-field label="Lien sendinblue (français)"
                                      v-model="creating.news"
                                      :rules="ValidationRules.required"></v-text-field>

                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row>
                      <v-col class="mr-2">
                        <v-text-field label="Titre (anglais)" v-model="creating.label_en"
                                      :rules="ValidationRules.required"></v-text-field>

                      </v-col>
                      <v-col class="ml-2">
                        <v-text-field label="Lien sendinblue (anglais)"
                                      v-model="creating.news_en"
                                      :rules="ValidationRules.required"></v-text-field>

                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-datetime-picker
                        label="Date de diffusion"
                        v-model="creating.date"
                        :key="cheatKey"
                        :timePickerProps="{format :'24hr'}"
                        :datePickerProps="{locale: $i18n.locale, 'first-day-of-week': '1'}"
                        :clear-text="$t('actions.clear')"
                        :dateFormat="getLocaleFormat($i18n.locale)"
                    >
                    </v-datetime-picker>
                  </v-col>
                  <v-col>
                    <v-datetime-picker
                        label="Fin de diffusion"
                        title="Laisser vide si pour toujours"
                        v-model="creating.expiration"
                        :timePickerProps="{format :'24hr'}"
                        :datePickerProps="{locale: $i18n.locale, 'first-day-of-week': '1'}"
                        :clear-text="$t('actions.clear')"
                        :dateFormat="getLocaleFormat($i18n.locale)"
                    >
                    </v-datetime-picker>
                  </v-col>
                </v-row>


                <v-container fluid class="pa-0">
                  <div class="title">Ajouter des modules</div>
                  <v-divider class="my-2"></v-divider>

                  <v-list dense class="mb-4">
                    <v-list-item v-for="module in creating.module" :key="module.id" dense class="mr-0 pa-0">
                      <v-list-item-avatar class="mr-4" tile size="24">
                        <planete-icon :dark="$vuetify.theme.dark">{{
                            getIcon(module) || "planete-online"
                          }}
                        </planete-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span>{{ getModule(module).subModules.find(a => a.path == module.path).name }}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="deleteSubModule(module)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-btn small class="ma-0 px-0 py-0" :color="displayModule ? 'error' : 'success'"
                         @click="clearDisplayModule">
                    <v-icon small class="ma-0 pa-0" icon>{{ displayModule ? "mdi-close" : "mdi-plus" }}</v-icon>
                  </v-btn>
                  <v-select v-if="displayModule"
                            label="Lier à un module"
                            :items="allModules"
                            v-model="creatingModule"
                            item-text="name"
                            return-object
                            class="mt-4"
                            @input="displaySubModule = true"
                            @change="scrollBot"

                  ></v-select>
                  <v-select v-if="displaySubModule"
                            label="Lier à un sous-module"
                            :items="submodules"
                            v-model="creatingSubModules"
                            return-object
                            item-text="name"
                            multiple
                  >
                    <template v-slot:append-outer>
                      <v-list-item v-if="creatingSubModules.length > 0">
                        <v-btn color="success" @click="addSubModules">Ajouter</v-btn>
                      </v-list-item>
                    </template>
                  </v-select>

                </v-container>


                <v-container fluid class="d-flex flex-row align-center justify-center">
                  <v-btn v-if="editing" text class="mr-4" @click="cancelEdit">Annuler</v-btn>
                  <v-btn color="primary" @click="addCommunication" :loading="sendingCom">
                    {{ editing ? "Modifier" : "Ajouter" }} la
                    communication
                  </v-btn>
                </v-container>

              </v-form>

            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
      <v-row class="mr-1">
        <v-col>
          <v-card class="" height="100%" max-height="500px">

            <v-layout fill-height class="d-flex flex-column">
              <v-toolbar color="transparent elevation-0">
                <v-toolbar-title>
                  Listes des communications
                </v-toolbar-title>
              </v-toolbar>

              <vue-scroll>
                <v-card-text>
                  <v-progress-circular color="primary" indeterminate v-if="loadingCom"></v-progress-circular>
                  <v-list dense>
                    <v-list-item v-for="(com, i) in getNews" :key="i" three-line>
                      <v-list-item-avatar tile>
                        <planete-icon :key="$uuid.v4()"></planete-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ com.label }}
                          <v-chip x-small class="ml-4 rounded" :color="chipOnline(com).color">
                            {{ chipOnline(com).text }}
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ getModules(com.module) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ dateIt(com.date) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action v-if="!com.date">
                        <v-btn x-small color="error" class="px-0 py-3" title="Diffuser maintenant"
                               @click="confirmDeploy(com)">
                          <v-icon>mdi-access-point</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action class="d-flex flex-row align-center my-2">
                        <v-btn icon @click="$router.push({name: 'PlaneteOnlineNews', params: { id: com._id }})">
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn icon @click="editCom(com)">
                          <v-icon>mdi-square-edit-outline</v-icon>
                        </v-btn>
                        <v-btn icon @click="confirmRemoveCommunication(com)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <div v-if="!news.length && !loadingCom">
                    Aucune communication enregistrée
                  </div>

                </v-card-text>
                <v-card-actions></v-card-actions>
              </vue-scroll>
            </v-layout>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mr-1">
        <v-col>
          <client-exclusion></client-exclusion>
        </v-col>
      </v-row>

      <v-dialog
          v-model="confirmDelete"
          max-width="480"
      >
        <v-card v-if="comDeleting">
          <v-card-title>Confirmation de suppression</v-card-title>
          <v-card-text>
            Voulez-vous vraiment supprimer cette communication ?<br>
            <template
                v-if="comDeleting.date && new Date(comDeleting.date) <= new Date() && (!comDeleting.expiration || new Date(comDeleting.expiration) > new Date())">
              <br>
              <v-icon color="warning" small class="mr-2">mdi-alert</v-icon>
              Cette communication est déjà en ligne ! En la supprimant tous les clients ayant accès à celle-ci ne la
              verront plus.
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="comDeleting = null;confirmDelete = false">Annuler</v-btn>
            <v-btn color="error" @click="removeCommunication(comDeleting)">Supprimer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="confirmSend"
          max-width="480"
      >
        <v-card v-if="comSending">
          <v-card-title>Confirmation de diffusion de communication</v-card-title>
          <v-card-text>
            Voulez-vous vraiment envoyer cette communication maintenant ?<br>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="comSending = null;confirmSend = false">Annuler</v-btn>
            <v-btn color="error" @click="sendCommunication(comSending)">Diffuser</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </vue-scroll>

</template>

<script>
import CommunicationService from "@/Services/Communication"
import Vuex from "vuex"
import MixinValidation from "@/Mixins/ValidationRules"
import ModuleService from "@/Services/ModuleService"
import moment from "moment"


export default {
  name: "PlaneteOnlineNewsCreation",
  mixins: [MixinValidation],
  components: {
    ClientExclusion: () => import("@/Components/Views/Home/Widgets/WidgetPlaneteOnlineNews/ClientExclusion")
  },
  computed: {
    ...Vuex.mapState(["modules", "informations"]),
    submodules() {
      if (this.creatingModule) {
        let module = this.allModules.find(mod => mod.id == this.creatingModule.id) // Cherche le module séléctionné
        return module.subModules.filter(sub => this.creating.module.map(a => a.path).indexOf(sub.path) === -1) // Retire les sous-modules déjà affectés à la com
      } else {
        return []
      }
    },
    getNews() {
      let news = this.news.map(a => {
        return {...a, date: a.date ? new Date(a.date) : null}
      })
      news.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      return news
    }
  },
  data() {
    return {
      moment,
      news: [],
      creating: {
        label: "",
        label_en: "",
        module: [],
        type: "sendinblue",
        news: "",
        news_en: "",
        date: null,
        expiration: null
      },
      toto: new Date(),
      valid: false,
      creatingModule: [],
      creatingSubModules: [],
      editing: false,
      displayModule: false,
      displaySubModule: false,
      confirmDelete: false,
      confirmDeleteList: false,
      confirmSend: false,
      comSending: null,
      comDeleting: null,
      listDeleting: null,
      allModules: [],
      sendingCom: false,
      loadingCom: false,
      cheatKey: null
    }
  },
  methods: {
    getIcon(mod) {
      let module = this.getModule(mod)
      return module ? module.icon : ""
    },
    async addCommunication() {
      if (this.$refs.comAdd.validate()) {
        this.sendingCom = true
        if (!this.editing) {
          let news = this.creating

          let createdNews = await CommunicationService.postNews(news)

          if (createdNews) {

            this.news.push(createdNews)
            this.$refs.comAdd.reset()

            let message
            if (news.date) {
              message = "Elle sera visible par les utilisateurs à partir du " + news.date.toLocaleDateString() + " à " + news.date.toLocaleTimeString()
            } else {
              message = "Elle ne sera pas visible par les utilisateurs tant qu'une date ne sera pas renseignée"
            }

            this.$nSuccess("Communication ajoutée", message, 8000)
          } else {
            this.$nError("Erreur lors de l'ajout de la communication")
          }

          this.creating = {
            label: "",
            label_en: "",
            module: [],
            type: "sendinblue",
            news: "",
            news_en: "",
            date: null,
            expiration: null
          }
          this.creatingModule = []
          this.creatingSubModules = []
        } else {
          let news = Object.assign({}, this.creating)

          let editedNews = await CommunicationService.editNews(news)

          if (editedNews) {
            let message
            if (editedNews.date) {
              if (!(editedNews.date instanceof Date)) {
                editedNews.date = new Date(editedNews.date)
              }
              message = "Elle sera visible par les utilisateurs à partir du " + editedNews.date.toLocaleDateString() + " à " + editedNews.date.toLocaleTimeString()
            } else {
              message = "Elle ne sera pas visible par les utilisateurs tant qu'une date ne sera pas renseignée"
            }
            if (editedNews.expiration) {
              if (!(editedNews.expiration instanceof Date)) {
                editedNews.expiration = new Date(editedNews.expiration)
              }
            }

            let i = this.news.findIndex(a => a._id == editedNews._id)
            this.news.splice(i, 1, editedNews)
            this.editing = false
            this.creating = {
              label: "",
              label_en: "",
              module: [],
              type: "sendinblue",
              news: "",
              news_en: "",
              date: undefined,
              expiration: null
            }
            this.creatingModule = []
            this.creatingSubModules = []
            this.$nSuccess("Communication modifiée", message, 8000)
          } else {
            this.$nError("Erreur lors de la modification de la communication")
          }
        }
        this.$refs.comAdd.reset()

        this.sendingCom = false
      }
    },
    confirmDeploy(com) {
      this.comSending = com
      this.confirmSend = true
    },
    confirmRemoveCommunication(news) {
      this.comDeleting = news
      this.confirmDelete = true
    },
    async sendCommunication(com) {
      com.date = new Date()
      let editedNews = await CommunicationService.editNews(com)

      if (editedNews) {

        let i = this.news.findIndex(a => a._id == editedNews._id)
        this.news.splice(i, 1, editedNews)
        this.confirmSend = false
        this.comSending = null

        this.$nSuccess("Communication envoyé", "", 8000)
      } else {
        this.$nError("Erreur lors de l'envoi de la communication")
      }
    },
    async removeCommunication(news) {
      let response = await CommunicationService.removeNews(news._id)

      let index = this.news.findIndex(a => a._id == news._id)
      this.news.splice(index, 1)

      this.$nSuccess("Communication supprimée")

      this.confirmDelete = false
      this.comDeleting = null
    },
    getModules(m) {
      let ret = []
      if (m) {
        ret = m.map(submodule => {
          let module = this.allModules.find(a => a.subModules.find(b => b.path == submodule.path))
          if (module) {
            let subModule = module.subModules.find(b => b.path == submodule.path)
            if (module && subModule) {
              return module.name + " - " + subModule.name
            } else {
              return ""
            }
          } else {
            return ""
          }
        }).filter(a => !!a).join(", ")
      }
      return ret
    },
    getModule(m) {
      let ret = this.allModules.find(mod => {
        return mod.subModules.some(submod => {
          return submod.path == m.path
        })
      })
      return ret
    },
    dateIt(d) {
      if (d) {
        let dt = d && !(d instanceof Date) ? new Date(d) : d
        return "Le " + dt.toLocaleDateString() + " à " + dt.toLocaleTimeString()
      } else {
        return "Aucune date"
      }
    },
    chipOnline(com) {
      if (com.date && !(com.date instanceof Date)) {
        com.date = new Date(com.date)
      }
      if (com.expiration && !(com.expiration instanceof Date)) {
        com.expiration = new Date(com.expiration)
      }
      if (com.date) {
        let now = new Date()
        if (com.date > now) {
          return {
            color: "primary",
            text: "Diffusion le " + com.date.toLocaleDateString() + " à " + com.date.toLocaleTimeString()
          }
        } else {
          if (com.expiration && com.expiration <= now) {
            return {
              color: "warning",
              text: "Expirée"
            }
          } else {
            return {
              color: "success",
              text: "En ligne"
            }
          }
        }
      } else {
        return {
          color: "error",
          text: "Aucune date de diffusion"
        }
      }
    },
    clearDisplayModule() {
      if (this.displayModule) {
        this.displayModule = false
        this.creatingModule = null
        this.displaySubModule = false
        this.creatingSubModules = []
      } else {
        this.displayModule = true
      }
    },
    addSubModules() {
      let $this = this
      this.creatingSubModules.forEach((v, k) => {
        $this.creating.module.push({
          path: v.path
          // slug: $this.slugify($this.creatingModule.name) + "_" + $this.slugify(v.name)
        })
      })
      this.creatingSubModules = []
      this.creatingModule = []
      this.displaySubModule = false
      this.displayModule = false
    },
    deleteSubModule(module) {
      let i = this.creating.module.findIndex(a => a.path == module.path)
      this.creating.module.splice(i, 1)
    },
    async editCom(com) {
      this.creating = JSON.parse(JSON.stringify(com))

      if (this.creating.date) {
        this.creating.date = com.date
      }
      if (this.creating.expiration) {
        this.creating.expiration = new Date(com.expiration)
      }
      this.creatingSubModules = []
      this.creatingModule = []
      this.displaySubModule = false
      this.displayModule = false
      this.editing = true
      await this.sleep(10)
      this.cheatKey = this.$uuid.v4()
    },
    cancelEdit() {
      this.editing = false
      this.creating = {
        label: "",
        label_en: "",
        module: [],
        type: "sendinblue",
        news_en: "",
        date: null,
        expiration: null
      }
      this.$refs.comAdd.reset()

    },
    easeInOutQuad(t) {
      return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    },
    async scrollBot() {
      let timer = s => new Promise(res => setTimeout(res, s))
      await timer(50)
      let scrollTop = this.$refs.cardComAdd.scrollTop
      let scrollHeight = this.$refs.cardComAdd.scrollHeight
      let realHeight = this.$refs.cardComAdd.clientHeight
      let delta = scrollHeight - realHeight
      let time = 50
      if (delta > scrollTop + 1) {
        for (let i = 0; i < time; i++) {
          let percentage = i / time
          await timer(1)
          this.$refs.cardComAdd.scrollTop = this.easeInOutQuad(percentage) * delta
        }
      }
    },
  },
  async created() {
    this.loadingCom = true

    if (this.informations.superAdministrator == false) {
      await this.$router.push({name: "NotFoundConnected"})
    }

    this.allModules = await ModuleService.getModules(this.$i18n.locale)
    this.allModules.filter(a => a.subModules)
    this.allModules.filter(a => a.subModules.filter(b => b.path != "").length > 0)
    this.allModules = this.allModules.map(a => {
      return {
        ...a,
        name: this.htmlDecode(a.name),
        slug: this.slugify(this.htmlDecode(a.name)),
        subModules: a.subModules.map(b => {
          return {
            ...b,
            name: this.htmlDecode(b.name),
            slug: this.slugify(this.htmlDecode(b.name)),
          }
        })
      }
    })


    this.news = await CommunicationService.getNews()
    this.loadingCom = false
  },
}
</script>

<style scoped>
.max-height {
  max-height: 345px;
}
</style>

